import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.12_next@15.0.3_@babel+core@7.25.8_@playwright+test@1.47.1_react-do_vr74czx7wcwrjn4gldfffpfojy/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.3_@babel+core@7.25.8_@playwright+test@1.47.1_react-dom@19.0.0-rc-69d4b800-20241021__gyzi2ufa3xeolzuheawgb3xrx4/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/ClashDisplay.woff2\",\"variable\":\"--font-clash\"}],\"variableName\":\"clashDisplayFont\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.3_@babel+core@7.25.8_@playwright+test@1.47.1_react-dom@19.0.0-rc-69d4b800-20241021__gyzi2ufa3xeolzuheawgb3xrx4/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/Melodrama.woff2\",\"variable\":\"--font-melodrama\"}],\"variableName\":\"melodramaFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["LazyDeviceContext"] */ "/vercel/path0/src/app/(providers)/LazyDeviceContext.ts");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/src/app/(providers)/ThemeProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/styles/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["AnalyticsPageView"] */ "/vercel/path0/src/modules/analytics/analytic-page-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AnalyticsIdentifyUser"] */ "/vercel/path0/src/modules/analytics/analytics-identify-user.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AnalyticsProvider"] */ "/vercel/path0/src/modules/analytics/analytics-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebVitals"] */ "/vercel/path0/src/modules/analytics/web-vitals.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RestoreUrl"] */ "/vercel/path0/src/modules/progressive-web-app/restore-url.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SupabaseProvider"] */ "/vercel/path0/src/modules/Supabase/SupabaseProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastProvider"] */ "/vercel/path0/src/modules/toast-provider/toast-provider.tsx");
