import React from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { Loader } from 'lucide-react';
import { cn } from 'src/utils/cn';

// TODO: create proper disabled styles
const buttonClass = cva(
  'outline-none  uppercase disabled:hover:cursor-default',
  {
    compoundVariants: [
      {
        className: 'py-2 px-2',
        padded: true,
        square: true,
      },
    ],
    defaultVariants: {
      bordered: false,
      padded: true,
      rounded: false,
      variant: 'primary',
    },
    variants: {
      bordered: {
        false: 'border-none',
        true: 'border border-primary/50 hover:border-primary',
      },
      padded: {
        true: 'py-2 px-6',
      },
      rounded: {
        true: 'rounded-lg',
      },
      square: {
        true: 'py-2 px-2',
      },
      variant: {
        primary:
          'bg-primary text-white hover:bg-primaryDark dark:text-dark dark:hover:bg-white disabled:bg-primary/80 disabled:hover:bg-primary/80',
        secondary:
          'bg-background/50 text-primary hover:bg-primary hover:text-white backdrop-blur dark:hover:text-dark',
        transparent:
          'bg-transparent text-primary hover:bg-primary hover:text-white dark:hover:text-dark',
      },
    },
  }
);

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonClass> & {
    loading?: boolean;
    align?: 'start' | 'center' | 'end';
  };

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      children,
      loading = false,
      type = 'button',
      align = 'start',
      // cva
      bordered,
      variant,
      padded,
      rounded,
      square,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={cn(
          'relative',
          buttonClass({ bordered, padded, rounded, square, variant }),
          className
        )}
        type={type}
        {...props}
      >
        {loading && (
          <div className="absolute inset-0 z-10 flex items-center justify-center">
            <Loader className="animate-loader-spin stroke-[2px]" />
          </div>
        )}
        <div
          className={cn('flex items-center gap-2', loading && 'invisible', {
            'justify-start': align === 'start',
            'justify-center': align === 'center',
            'justify-end': align === 'end',
          })}
        >
          {children}
        </div>
      </button>
    );
  }
);

Button.displayName = 'Button';
