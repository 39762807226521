'use client';

import React, { createContext, useContext } from 'react';
import { useRouter } from 'next/navigation';
import type { Session, SupabaseClient } from '@supabase/supabase-js';
import { Database } from 'src/@types/database.types';
import { useUserActions } from 'src/store/user/userStore';
import { getSupabaseBrowserClient } from 'src/utils/supabase/getSupabaseBrowserClient';

type SupabaseContext = {
  supabase: SupabaseClient<Database>;
};

const Context = createContext<SupabaseContext | undefined>(undefined);

export function SupabaseProvider({ children }: { children: React.ReactNode }) {
  const { setUser, resetUser } = useUserActions();
  const router = useRouter();
  const [supabase] = React.useState(getSupabaseBrowserClient());

  const setUserFromSession = React.useCallback(
    (session: Session) => {
      setUser({
        accessToken: session.access_token,
        avatar_url: session.user.user_metadata['avatar_url'],
        id: session.user.id,
        name: session.user.user_metadata['name'],
      });
    },
    [supabase]
  );

  React.useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'TOKEN_REFRESHED') {
        router.refresh();
      } else if (session) {
        setUserFromSession(session);
      } else {
        resetUser();
        router.refresh();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [router]);

  React.useEffect(() => {
    supabase.auth.getSession().then(({ data, error }) => {
      if (!error && data.session?.user) {
        setUserFromSession(data.session);
      } else {
        resetUser();
      }
    });
  }, []);

  return <Context.Provider value={{ supabase }}>{children}</Context.Provider>;
}

export const useSupabase = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('useSupabase must be used inside SupabaseProvider');
  } else {
    return context;
  }
};
