'use client';

import {
  CircleAlertIcon,
  CircleCheckBig,
  InfoIcon,
  LoaderIcon,
  TriangleAlertIcon,
} from 'lucide-react';
import { Toaster } from 'sonner';
import { useIsMobile } from 'src/app/(providers)/DeviceContext';

export function ToastProvider() {
  const isMobile = useIsMobile();
  return (
    <Toaster
      position={isMobile ? 'top-center' : 'top-right'}
      offset={0}
      icons={{
        error: <CircleAlertIcon className="size-[18px] stroke-[2px]" />,
        info: <InfoIcon className="size-[18px] stroke-[2px]" />,
        loading: (
          <LoaderIcon className="size-[18px] animate-loader-spin stroke-[2px]" />
        ),
        success: <CircleCheckBig className="size-[18px] stroke-[2px]" />,
        warning: <TriangleAlertIcon className="size-[18px] stroke-[2px]" />,
      }}
      richColors
      expand={!isMobile}
      toastOptions={{
        className: 'w-auto ml-auto mt-10 sm:mt-16 mr-8 right-0',
      }}
    />
  );
}
